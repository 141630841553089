import React, { useEffect, useState } from "react";
import "./footer.css";
import { useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';

export default function Footer() {
  const [audioPlay, setAudioPlay] = useState(false);
  const location = useLocation();
  const audio = new Audio("../../music.mp3");
  const audioRef = React.useRef<HTMLAudioElement>(audio);
  useEffect(() => {
    // storing audio state
    localStorage.setItem("audio", JSON.stringify(audioPlay));
    audioRef.current.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function play() {
    setAudioPlay(true);
    audioRef.current.play();
  }

  function pause() {
    setAudioPlay(false);
    audioRef.current.pause();
  }

  return (
    <div
      className={
        location.pathname === "/faqs" ? "page-footer-faq" : "page-footer"
      }
    >
      <div className="footer d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column justify-content-center footer-main">
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            href="https://app.uniswap.org/#/swap?inputCurrency=0xf0f9d895aca5c8678f706fb8216fa22957685a13&chain=mainnet"
            rel="noreferrer"
          >
            <p className="footer-text-first mb-1">Buy CULT now on Uniswap</p>
          </a>
          <div className="d-flex justify-content-start social-link">
            <a target="blank" href="https://twitter.com/wearecultdao">
              <img src="/assets/twitter-icon.png" alt="img" />
            </a>
            <a target="blank" href="https://discord.gg/cultdao">
              <img src="/assets/discord-icon.png" alt="img" />
            </a>
            <a target="blank" href="https://t.me/cultdao">
              <img src="/assets/tg-icon.png" alt="img" />
            </a>
            <a target="blank" href="https://doc.cultdao.io/">
              <img src="/assets/gitbook-icon.png" alt="img" />
            </a>
            <a
              className="footer-main"
              target="blank"
              href="https://wearecultdao.medium.com/"
            >
              <img src="/assets/medium-icon.png" alt="img" />
            </a>
          </div>
        </div>
        <p className="footer-text mb-0">Become part of the Cult</p>
        { !isMobile && <div className="play-icon-container">
          {audioPlay === true ? (
            <img src="/assets/play.png" alt="img" onClick={() => pause()} />
          ) : (
            <img src="/assets/pause.png" alt="img" onClick={() => play()} />
          )}

          <audio autoPlay preload="none" ref={audioRef} id="audioPlay">
            <source src="/assets/audio_bg.mp3" type="audio/mpeg" />
          </audio>
        </div> }
      </div>
    </div>
  );
}
